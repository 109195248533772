exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agbs-tsx": () => import("./../../../src/pages/agbs.tsx" /* webpackChunkName: "component---src-pages-agbs-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-en-tsx": () => import("./../../../src/pages/kontakt.en.tsx" /* webpackChunkName: "component---src-pages-kontakt-en-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-news-en-tsx": () => import("./../../../src/pages/news.en.tsx" /* webpackChunkName: "component---src-pages-news-en-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-unsere-arbeit-en-tsx": () => import("./../../../src/pages/UnsereArbeit.en.tsx" /* webpackChunkName: "component---src-pages-unsere-arbeit-en-tsx" */),
  "component---src-pages-unsere-arbeit-tsx": () => import("./../../../src/pages/UnsereArbeit.tsx" /* webpackChunkName: "component---src-pages-unsere-arbeit-tsx" */),
  "component---src-pages-unsere-dienstleistungen-en-tsx": () => import("./../../../src/pages/UnsereDienstleistungen.en.tsx" /* webpackChunkName: "component---src-pages-unsere-dienstleistungen-en-tsx" */),
  "component---src-pages-unsere-dienstleistungen-tsx": () => import("./../../../src/pages/UnsereDienstleistungen.tsx" /* webpackChunkName: "component---src-pages-unsere-dienstleistungen-tsx" */)
}

